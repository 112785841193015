<template>
    <div>
        <div class="box">
            <el-page-header @back="$router.go(-1)" :content="content"></el-page-header>
        </div>
        <div class="box bbb mt20">
            <el-input v-model="info.title" />
			<br>
            <br>
            <div v-if="load">
                <SummerNote :id="'summernote' + new Date().getTime()" v-model="info.content" />
            </div>
			<el-button  class="btn" type="primary" @click="save">{{$t('btnTip.submit')}}</el-button>
        </div>
    </div>
</template>
<script>
import SummerNote from '@/components/summernote';
import { serviceTermDetail, serviceTermEdit} from '@/api/index'
export default {
    name:'serviceTermEdit',
    data(){
        return {
            id:'',
            info:{
                id:'',
                title:'',
                content:''
            },
            load:false
        }
    },
    components:{SummerNote},
    created(){
        this.id=this.$route.query.id
        this.get_info(this.id)
    },
    computed:{
        content(){
			return this.id === undefined ? this.$t('btnTip').add : this.$t('btnTip').edit
		},
    },
    methods:{
        async get_info(id){
            const res = await serviceTermDetail(id)
            this.info=res
            this.load=true
        },
        async save(){
            await serviceTermEdit(this.info)
            this.$message.success({
                message:this.$t('changeSuccess')
            })
        }
    },
}
</script>
<style land="scss" scoped>
.bbb{
	position: relative;
	padding-bottom: 50px;
}
.bbb .btn{
	position: absolute;
	bottom:3px;
	right: 20px;
}
</style>
